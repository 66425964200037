<template>

  <div class="flex flex-row text-l justify-center gap-4">
    <button v-if="selectedIndex === 0" class="cursor-pointer bg-gradient-to-r from-green-400 to-teal-400 text-gray-900">
      Leaderboard
    </button>

    <button v-else class="cursor-pointer" @click="leaderboardSelected()">Leaderboard</button>

    <button v-if="selectedIndex === 1" class="cursor-pointer bg-gradient-to-r from-green-400 to-teal-400 text-gray-900">
      Rankings
    </button>
    <button v-else class="cursor-pointer" @click="rankingSelected()">Rankings</button>

    <button v-if="selectedIndex === 2" class="cursor-pointer bg-gradient-to-r from-green-400 to-teal-400 text-gray-900">
      Stats
    </button>
    <button v-else class="cursor-pointer" @click="statsSelected()">Stats</button>
  </div>
  <Leaderboard v-if="selectedIndex === 0" />
  <Ranking v-else-if="selectedIndex === 1"/>
  <div v-else-if="selectedIndex === 2">
    <loading-spinner v-if="loading"></loading-spinner>
    <div v-if="stats && !loading" class="flex flex-col justify-between mt-6 gap-1">

      <div class="flex flex-row">
        <div class="flex flex-col flex-1">
          <p class="text-2xl text-gradient font-extrabold">{{ stats.allTimeGamesPlayed }}</p>
          <div class="text-sm">
            Played
          </div>
        </div>

        <div class="flex flex-col flex-1">
          <p class="text-2xl text-gradient font-extrabold">{{ stats.allTimeScore }}</p>
          <div class="text-sm">
            Total Score
          </div>
        </div>

        <div class="flex flex-col flex-1">
          <p class="text-2xl text-gradient font-extrabold">{{ averageScore }}</p>
          <div class="text-sm">
            Average Score
          </div>

        </div>
      </div>
      <div class="flex flex-row">
        <div class="flex flex-col flex-1">
          <p class="text-2xl text-gradient font-extrabold">{{ stats.streak }}</p>
          <div class="text-sm">
            Current Streak
          </div>
        </div>


        <div class="flex flex-col flex-1">
          <p class="text-2xl text-gradient font-extrabold">{{ stats.allTimeBestStreak }}</p>
          <div class="text-sm">
            Best Streak
          </div>
        </div>
      </div>

      <div class="mt-4">
        <canvas id="myChart"></canvas>
      </div>
    </div>
  </div>
</template>
<script setup>

import Leaderboard from "./Leaderboard.vue";
import {computed, onMounted, ref, watch} from "vue";
import {useStats} from "../composables/useStats.js";
import LoadingSpinner from "./LoadingSpinner.vue";
import {Chart, registerables} from "chart.js";
import Ranking from "./Rankings.vue";

Chart.register(...registerables);
const {getStats, stats, loading} = useStats();

let selectedIndex = ref(0);

onMounted(async () => {
  await leaderboardSelected()
})

watch(stats, () => {
  const ctx = document.getElementById('myChart');
  const scores = stats.value.scores;
  let averages = [];
  let total = 0;
  for (let i = 0; i < scores.length; i++) {
    const index = i + 1;
    const current = scores[i].score;
    total += current;
    averages.push(total / index);
  }
  const labels = scores.map(e => e.date);
  const data = {
    labels: labels,
    datasets: [{
      data: scores.map(e => e.score),
      fill: true,
      backgroundColor: 'rgb(66,222,136, 0.1)',
      borderColor: 'rgb(66,222,136)',
      tension: 0.4,
      label: `Day's Score`
    }, {
      data: averages,
      fill: false,
      backgroundColor: 'rgb(120,195,251, 0.1)',
      borderColor: 'rgb(120,195,251)',
      tension: 0.4,
      label: `Average Score`
    }]
  };

  new Chart(ctx, {
    type: 'line',
    data: data,
    options: {
      elements: {
        point: {
          radius: 0
        }
      },
      scales: {
        x: {
          ticks: {
            display: false
          }
        },
        y: {
          beginAtZero: true
        }
      }
    }
  });
})

function leaderboardSelected() {
  selectedIndex.value = 0;
}

function rankingSelected() {
  selectedIndex.value = 1;
}

async function statsSelected() {
  selectedIndex.value = 2;
  await getStats()
}

const averageScore = computed(() => {
  if (stats.value.allTimeGamesPlayed === 0) {
    return 0;
  }
  return (stats.value.allTimeScore / stats.value.allTimeGamesPlayed).toFixed(1).replace(/\.?0+$/, "");
})

</script>