import {computed, ref} from "vue";
import {useGrid} from "./useGrid.js";
import {useWords} from "./useWords.js";
import seedrandom from 'seedrandom';
import {useUserState} from "./useUserState.js";


export const useGameEngine = () => {
    const {currentWords, getWords, loading: loadingWords, completedWords, over, makeGuess} = useWords();
    const {grid, selectedLetter, populateGrid, revealWords, foundWords} = useGrid();
    const {syndleUser, createUser, loading: loadingUser} = useUserState();
    let score = ref(0);
    let xp = ref(syndleUser.value.xp);

    let wrongGuessCount = ref(0);
    let seenLetters = ref([]);
    let words = ref([]);

    const STARTED = "started";
    const COMPLETED = "completed";
    const FAILED = "failed";

    let state = ref(STARTED);

    async function initialiseGame() {
        await setUserState();
        await getWords();
        await setWordState();
    }

    async function setUserState() {
        score.value = syndleUser.value.game;
         if (syndleUser.value.over) {
            seenLetters.value = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
        } else {
            seenLetters.value = syndleUser.value.guessedLetters ?? [];
        }
    }

    async function nextGame() {
        state.value = STARTED;
        seenLetters.value = [];
        // localStorage.setItem('seenLetters', JSON.stringify(seenLetters.value));
        wrongGuessCount.value = 0;
        words.value = currentWords.value.map(w => w.toUpperCase());
        populateGrid(words.value);
        await selectRandomLetters();
    }

    async function setWordState() {
        words.value = currentWords.value.map(w => w.toUpperCase());
        populateGrid(words.value);
        await selectRandomLetters();
        wrongGuessCount.value = 0;
        for (let letter of seenLetters.value) {
            processGuess(letter);
        }
    }

    async function letterClicked(letter) {
        if (seenLetters.value.includes(letter)) {
            return;
        }
        seenLetters.value.push(letter);
        makeGuess([letter]);
        processGuess(letter);
        if (state.value === FAILED) {
            await over();
        }

        if (state.value === COMPLETED) {
            const completed = await completedWords();
            score.value = completed.game;
            xp.value = completed.xp;
            if (score.value === 100) {
                await over();
            }
        }
    }

    function processGuess(letter) {
        const correctGuess = selectedLetter(letter);
        if (!correctGuess) {
            wrongGuessCount.value++;
        }
        if (wrongGuessCount.value >= 6) {
            state.value = FAILED;
            revealWords();
        }
        if (correctGuesses.value.length === correctLetters.value.length) {
            state.value = COMPLETED;
            seenLetters.value = []; // this is if the player refreshes the screen after completing
        }
    }

    async function selectRandomLetters() {
        if (seenLetters.value.length > 0) {
            return;
        }
        if (seenLetters.value.length === 0) {
            const wordsCombined = words.value.join("");
            const letter1 = getRandomLetter('', wordsCombined);
            const letter2 = getRandomLetter(letter1, wordsCombined);
            [letter1, letter2].forEach(letter => {
                selectedLetter(letter);
                seenLetters.value.push(letter);
            });
            await makeGuess(seenLetters.value);
        }
    }

    const loading = computed(() => {
        return loadingUser.value || loadingWords.value;
    })

    function isLetterACorrectGuess(letter) {
        return correctLetters.value.length > 0 && correctGuesses.value.includes(letter);
    }

    function isLetterAWrongGuess(letter) {
        return correctLetters.value.length > 0 && seenLetters.value.includes(letter) && !correctGuesses.value.includes(letter);
    }

    const correctGuesses = computed(() => {
        let correct = [];
        for (let seen of seenLetters.value) {
            if (correctLetters.value.includes(seen)) {
                correct.push(seen);
            }
        }
        return correct;
    })

    const correctLetters = computed(() => {
        const allLetters = words.value.join("").split("");
        return [...new Set(allLetters)];
    });


    function getRandomLetter(excludeLetter, words) {
        let seed = score.value + 1;
        let randomLetter = excludeLetter;
        do {
            let randomIndex = Math.floor(seedrandom(seed++)() * words.length);
            randomLetter = words[randomIndex];
        } while (randomLetter === excludeLetter);
        return randomLetter;
    }

    function getFormattedDate() {
        const currentDate = new Date();
        const year = currentDate.getUTCFullYear();
        const month = currentDate.getUTCMonth() + 1;
        const day = currentDate.getUTCDate();
        return `${year}-${month}-${day}`;
    }


    const puzzleFinished = computed(() => {
        return state.value === COMPLETED || state.value === FAILED;
    });

    const failedGame = computed(() => {
        return state.value === FAILED || score.value === 100; // not a very nice flag for completing
    })

    return {
        nextGame,
        initialiseGame,
        wrongGuessCount,
        puzzleFinished,
        failedGame,
        isLetterAWrongGuess,
        isLetterACorrectGuess,
        seenLetters,
        grid,
        letterClicked,
        score,
        correctGuesses,
        loading,
        foundWords,
        xp
    }
}