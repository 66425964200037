import {ref} from 'vue';

let loading = ref(false);
let scores = ref([]);
let userPosition = ref(-1);
let cachedScores = ref({})
let rankings = ref([]);
let rankingPosition = ref([]);

async function getScores(numberOfDaysBack) {
    if (numberOfDaysBack !== 0 && cachedScores.value[numberOfDaysBack]) {
        scores.value = cachedScores.value[numberOfDaysBack];
    } else {
        loading.value = true;
        const response = await fetch(`${import.meta.env.VITE_APP_API_BASE_URL}/api/scores?fromToday=${numberOfDaysBack}`);
        loading.value = false;
        if (response.ok) {
            scores.value = await response.json();
            cachedScores.value[numberOfDaysBack] = scores.value;
        } else {
            console.error("Failed to fetch scores", response.statusText);
        }
    }
    setUserPosition();
}

async function getRankings() {
    loading.value = true;
    const response = await fetch(`${import.meta.env.VITE_APP_API_BASE_URL}/api/users/rankings`);
    loading.value = false;
    if (response.ok) {
        rankings.value = await response.json();
    } else {
        console.error("Failed to fetch rankings", response.statusText);
    }
    setRankingPosition();
}

function setUserPosition() {
    const userId = localStorage.getItem('userId');
    const leaderboardPosition = scores.value.findIndex(f => f.id === userId);
    userPosition.value = leaderboardPosition + 1;
}

function setRankingPosition() {
    const userId = localStorage.getItem('userId');
    const leaderboardPosition = rankings.value.findIndex(f => f.id === userId);
    rankingPosition.value = leaderboardPosition + 1;
}


// Export the state and functions directly
export const useScores = () => ({
    scores,
    getScores,
    loading,
    setUserPosition,
    userPosition,
    rankings,
    rankingPosition,
    getRankings
});
