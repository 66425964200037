<template>
  <div class="fixed bottom-0 left-0 w-full bg-gray-600 h-8">
    <ConfettiExplosion v-if="showConfetti" :particleCount="200" :force="0.3" :colors="['#2dd4bf', '#4ade80', '#1a1a1a']" />
    <div class="relative h-8 mx-auto bg-gray-600 overflow-hidden">
      <div
          class="bg-gradient-to-r from-green-400 to-teal-400  h-8 absolute top-0 left-0"
          :style="{ width: `${progressPercentage}%` }"
      ></div>
      <div class="absolute left-1 mt-1">Lvl {{ currentLevel }}</div>
      <div class="absolute right-1 mt-1">Lvl {{ nextLevel }}</div>

      <div class="absolute inset-0 flex justify-center items-center text-black font-bold" :class="{'pop-animation': isPopping}">
        {{ xp }} / {{ nextLevelXp }} XP
      </div>
    </div>
  </div>
</template>

<script setup>

import ConfettiExplosion from "vue-confetti-explosion";
import {computed, ref, watch} from "vue";
import {useLevels} from "../composables/useLevels.js";

const props = defineProps({
  xp: Number,
  isPopping: Boolean
});

const { calculateCurrentLevel, calculateNextLevelXp } = useLevels();
const levelConstant = 50;

const showConfetti = ref(false);

const xp = computed(() => props.xp);

const nextLevel = computed(() => currentLevel.value + 1);
const currentLevel = computed(() => {
  return calculateCurrentLevel(xp.value);
});

const nextLevelXp = computed(() => {
  return calculateNextLevelXp(xp.value, currentLevel.value);
});

watch(currentLevel, (after, before) => {
  if (before >= 1 && after !== before) {
    showConfetti.value = true
    setTimeout(() => showConfetti.value = false,3000);
  }
});

const progressPercentage = computed(() => {
  const currentLevelXp = nextLevelXp.value - levelConstant * currentLevel.value ** 1.2;
  const xpProgress = xp.value - currentLevelXp; // XP gained within this level
  const xpForNext = nextLevelXp.value - currentLevelXp; // Total XP needed for this level
  return Math.min((xpProgress / xpForNext) * 100, 100); // Calculate percentage and cap at 100%
});</script>


<style scoped>

something {
  color: #2dd4bf;
  color: #4ade80;
  color: #1a1a1a;
}
</style>