<template>
  <div class="w-full">
    <div class="flex flex-col mt-2 ">
      <div class="flex flex-row justify-between items-center mt-2 mb-4">
        <div class="flex flex-col flex-1 center justify-center">
          <p class="text-xl text-center text-gradient font-extrabold">Rankings</p>
        </div>
      </div>
      <table>
        <thead class="border-blue-200 border-b-2 border-t-2">
        <tr class="m-4 mt-6">
          <th>
            Rank
          </th>
          <th>
            Name
          </th>
          <th>
            Level
          </th>
          <th>
            XP
          </th>
        </tr>
        </thead>

        <tbody>
        <tr v-if="loading">
          <td colspan="3" class="text-center">
            <LoadingSpinner></LoadingSpinner>
          </td>
        </tr>
        <tr v-else v-for="(score, index) in rankings" :class="{ 'text-green-400' : index === rankingPosition - 1}">
          <td>
            <font-awesome-icon :icon="['fas', 'trophy']" class="text-gold-500" v-if="index === 0"/>
            <font-awesome-icon :icon="['fas', 'trophy']" class="text-silver-500" v-if="index === 1"/>
            <font-awesome-icon :icon="['fas', 'trophy']" class="text-bronze-500" v-if="index === 2"/>
            <p v-else>{{ index > 2 ? index + 1 : '' }}</p>
          </td>
          <td>
            <div class="flex justify-center items-center">
              <div>
                {{ score.name }}
              </div>
              <div class="ml-2" v-if="score.verified">
                <img src="/favicon-16x16.png" title="Syndles Member"/>
              </div>
            </div>
          </td>
          <td>{{ calculateCurrentLevel(score.xp) }}</td>
          <td>{{ score.xp }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>
<script setup>

import {onMounted} from "vue";
import {useScores} from "../composables/useScores.js";
import LoadingSpinner from "./LoadingSpinner.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useLevels} from "../composables/useLevels.js";

const {rankings, getRankings, loading, rankingPosition} = useScores();
const {calculateCurrentLevel} = useLevels();

onMounted(async () => {
  await getRankings();
})

</script>


<style scoped>

</style>