

const levelConstant = 50;
function calculateCurrentLevel(xp) {
    let total = 0;
    let lvl = 1;
    while (total <= xp) {
        total += levelConstant * lvl ** 1.2;
        lvl += 1;
    }
    return lvl - 1;
}

function calculateNextLevelXp(xp, currentLevel) {
    let total = 0;
    let lvl = 1;
    while (lvl <= currentLevel) {
        total += levelConstant * lvl ** 1.2;
        lvl += 1;
    }
    return Math.floor(total);
}

export const useLevels = () => ({
    calculateCurrentLevel,
    calculateNextLevelXp
});
